.ReactModal__Content.ReactModal__Content--after-open {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px !important;
  padding: 16px 24px !important;
  width: 450px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;

  /* Grey / 600 */
  color: #4b5563;
}

.ReactModal__Content div {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;

  /* Grey / 600 */
  color: #4b5563;
}

form {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 31px;
}

button {
  margin-left: 26px;
}

.popup-cancel-btn {
  color: #4b5563;
}
.loader-center{
  text-align: center;
  }

.btn{
position: absolute;
bottom: 0;
right: 0;
margin-bottom: 10px;
margin-right: 10px;
z-index:999;
cursor: pointer;
}  